<template>
  <div style="max-height: 600px; overflow: scroll">
    <v-list>
      <v-list-item v-for="song in playlist" :key="song.id">
        <v-list-item-content>
          <v-list-item-title class="font-weight-bold">{{
            song.song
          }}</v-list-item-title>
          <v-list-item-subtitle>{{ song.artist }}</v-list-item-subtitle>
          <div style="position: absolute; font-size: 10pt; left: 50%; top: 25%">
            <v-icon small>mdi-tag-outline</v-icon> {{ song.name }}
          </div>
        </v-list-item-content>
        <v-list-item-action v-if="admin">
          <v-btn small tile color="error" @click.prevent="deleteSong(song.id)"
            ><v-icon small>mdi-delete</v-icon></v-btn
          >
        </v-list-item-action>
      </v-list-item>
    </v-list>
  </div>
</template>

<script>
import PlaylistApi from "../api/PlaylistApi";

export default {
  name: "playlistList",
  props: {
    playlist: Array,
    admin: { type: Boolean, default: false },
  },
  data: () => ({}),
  methods: {
    deleteSong(songId) {
      PlaylistApi.deleteSong(songId).then(() => {
        this.$emit("update");
      });
    },
  },
  computed: {},
};
</script>

<style scoped></style>
