export default {
  answers: [
    {
      id: "a1",
      name: "Yes, I’m in!",
      key: "show",
      value: "show",
      type: "button",
      parents: ["q1"],
      justify: "end",
    },
    {
      id: "a2",
      name: "Sorry, I cannot join!",
      key: "show",
      value: "noshow",
      type: "button",
      parents: ["q1"],
      justify: "end",
    },
    {
      id: "a3",
      key: "guestName",
      value: "",
      description: "Name",
      type: "textfield",
      parents: ["q2"],
      justify: "end",
    },
    {
      id: "a103",
      key: "guestName",
      value: "",
      description: "Name",
      type: "textfield",
      parents: ["q22"],
      justify: "end",
    },
    {
      id: "a105",
      key: "company",
      value: "",
      name: "My company would have been: ",
      description: "Name of company",
      type: "combobox",
      parents: ["q103"],
      justify: "end",
    },
    {
      id: "a4",
      key: "freitext",
      value: "",
      description: "Comments",
      type: "textfield",
      parents: ["q4"],
      justify: "end",
    },
    {
      id: "a5",
      key: "company",
      value: "",
      name: "I will come to your wedding and my company is: <br>",
      description: "name of company",
      type: "combobox",
      parents: ["q3"],
      justify: "end",
    },
  ],
  questions: [
    {
      id: "q1",
      name: "Hello, please let us know whether you can come to our wedding.",
      type: "q",
      parents: [],
      justify: "start",
    },
    {
      id: "q2",
      name: "Very nice.<br> What is your name? <br>You can tell us your company in the next step.",
      type: "q",
      parents: ["a1"],
      justify: "start",
    },
    {
      id: "q22",
      name: "That's too bad :-( <br> What is your name?",
      type: "q",
      parents: ["a2"],
      justify: "start",
    },
    {
      id: "q3",
      name: "Who accompanies you?",
      type: "q",
      parents: ["a3"],
      justify: "start",
    },
    {
      id: "q103",
      name: "Who would have accompanied you?",
      type: "q",
      parents: ["a103"],
      justify: "start",
    },
    {
      id: "q4",
      name: "Would you like to leave a message or greetings? Do you need a high chair for a child? Are there any allergies or intolerances?",
      type: "q",
      parents: ["a5", "a105"],
      justify: "start",
    },
  ],
};
