import store from "../store";

export default (to, from, next) => {
  const user = store.getters.getUser;
  console.log("current user:", user);
  if (user) {
    if (store.getters.getUser.group === "user") {
      console.log("AuthGuard sagt 'OK'");
      next();
    }
  } else {
    console.log("YOU SHALL NOT PASS!");
    next("/");
  }
};
