import { render, staticRenderFns } from "./Admin.vue?vue&type=template&id=fb3d0538&scoped=true&"
import script from "./Admin.vue?vue&type=script&lang=js&"
export * from "./Admin.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fb3d0538",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VContainer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VContainer})
