import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import { i18n } from "./plugins/i18n";
import VueChatScroll from "vue-chat-scroll";
import VueScrollAnimation from "./directives/scrollanimation";
import { fireAuth } from "./plugins/firebaseConfig";
import * as VueGoogleMaps from "vue2-google-maps";
import "./plugins/font.css";
import JsonExcel from "vue-json-excel";

Vue.use(VueChatScroll);
Vue.component("downloadExcel", JsonExcel);
Vue.config.productionTip = false;

Vue.directive("scrollanimation", VueScrollAnimation);
Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyAO7ZUciem9ZgaQBpXgeExNrquaPhB9YOU",
    libraries: "places",
  },
});

fireAuth.onAuthStateChanged((user) => {
  new Vue({
    router,
    store,
    vuetify,
    i18n,
    render: (h) => h(App),
    beforeCreate() {
      this.$store.commit("SET_LOADING", true);
      this.$store.dispatch("setTexts", 0);
      if (user) {
        this.$store.dispatch("autoSignIn", user);
      } else {
        console.info("not logged in");
        this.$store.commit("SET_LOADING", false);
        this.$store.commit("ERROR", {
          state: true,
          message: "Nicht angemeldet!",
        });
      }
    },
  }).$mount("#app");
});
