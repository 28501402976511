<template>
  <v-container
    fluid
    class="ma-0 pa-0"
    style="height: 100%; background-color: #80deea"
  >
    <router-view></router-view>
  </v-container>
</template>

<script>
export default {
  name: "Admin",
};
</script>

<style scoped></style>
