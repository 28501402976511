import firebase from "firebase";

const firebaseConfig = {
  apiKey: "AIzaSyCrs0vd-zFstfdWBDOyQb7Dbrc1KWRB2Jw",
  authDomain: "wedding-d85609.firebaseapp.com",
  databaseURL: "https://wedding-d85609.firebaseio.com",
  projectId: "wedding-d85609",
  storageBucket: "wedding-d85609.appspot.com",
  messagingSenderId: "548168097260",
  appId: "1:548168097260:web:0d07f9716fb10abe9134c9",
  measurementId: "G-40S1KVG2L4",
};
const firebaseApp = firebase.initializeApp(firebaseConfig);
const fireAuth = firebase.auth();
const firestore = firebaseApp.firestore();

export { fireAuth, firestore };
export default firebaseApp.firestore();
