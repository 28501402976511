<template>
  <v-app>
    <header-component />
    <v-main class="bgImage">
      <router-view />
    </v-main>
    <v-row v-if="debugmode">
      <v-col>
        error: {{ error }}<br />
        loading: {{ loading }}<br />
        user: {{ user }}
      </v-col>
    </v-row>
    <footer-component />
  </v-app>
</template>

<script>
import { mapGetters } from "vuex";
import HeaderComponent from "./components/Header";
import FooterComponent from "./components/Footer";

export default {
  name: "App",
  components: { HeaderComponent, FooterComponent },
  data: () => ({
    debugmode: false,
  }),
  methods: {},
  computed: {
    ...mapGetters({
      error: "getError",
      loading: "getLoading",
      user: "getUser",
    }),
  },
};
</script>
<style>
@font-face {
  font-family: bombshell;
  src: local("bombshell"), url("./assets/bombshell.ttf") format("truetype");
}
@font-face {
  font-family: joselinsans;
  src: local("joselinsans"), url("./assets/joselinsans.ttf") format("truetype");
}
.background {
  background-image: url("./assets/39659-O27XML.jpg");
  background-size: cover;
  background-attachment: fixed;
  background-position-x: center;
  background-position-y: bottom;
  height: 100%;
  padding: 0;
  margin: 0;
}
.bombshell {
  font-family: bombshell, serif !important;
}
.bgImage {
  background-image: url("assets/Himmel.jpg");
  background-position: top;
  height: 100%;
  padding: 0;
  margin: 0;
}
</style>
