import guestlist from "./data/csvjson.json";

export const state = {
  jsonlist: null,
};
export const mutations = {
  setJson(state, payload) {
    state.jsonlist = payload;
  },
};
export const actions = {
  initGuests({ commit }) {
    let load = [];
    for (let p in guestlist) {
      load.push(guestlist[p]);
    }

    commit("setJson", load);
  },
};
export const getters = {
  getJson: (state) => {
    return state.jsonlist;
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};
