<template>
  <v-img v-scrollanimation class="topImage" :src="mhFoto" :gradient="gradient">
    <timer v-if="isAuth" class="countdownComponent" :starttime="startTime" />
    <v-toolbar class="toolbar" rounded>
      <v-tabs color="weddinggreen" right>
        <template v-if="isAuth">
          <v-tab to="/wedding"> <v-icon>mdi-home</v-icon>Home</v-tab>
          <v-tab to="/" @click="logOut">logout</v-tab>
          <v-tab icon @click="switchLanguage(1)" class="langbox">
            <v-img
              class="icon"
              :src="require('@/assets/english.png')"
              contain
            ></v-img>
          </v-tab>
          <v-tab icon @click="switchLanguage(0)" class="langbox"
            ><v-img
              class="icon"
              :src="require('@/assets/german.png')"
              contain
            ></v-img
          ></v-tab>
        </template>
      </v-tabs>
    </v-toolbar>
  </v-img>
</template>

<script>
import Timer from "./features/Timer";

export default {
  components: { Timer },
  data: () => ({
    startTime: "Jun 18, 2022 12:00:00",
    mhFoto: require("@/assets/mhFoto.jpg"),
    gradient: "to top, rgba(255,255,255 ,.2), rgba(255,255,255,.7)",
  }),
  computed: {
    options() {
      return {
        duration: 1000,
        offset: 0,
        easing: "easeInOutCubic",
      };
    },
    isAuth() {
      return this.user !== null && this.user !== undefined;
    },
    user() {
      return this.$store.getters.getUser;
    },
  },
  methods: {
    switchLanguage(nr) {
      console.log("setText Header", nr);
      this.$store.dispatch("setTexts", nr);
    },
    logOut() {
      this.$store.dispatch("userLogOut");
    },
  },
};
</script>

<style scoped>
.header {
  position: relative;
}
.langbox {
  width: 10px;
  border: 0 solid;
  min-width: 60px !important;
}
.icon {
  height: 50%;
  width: 17%;
}
.topImage {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 25%;
  object-fit: cover;
}
.toolbar {
  position: absolute;
  border: 0 solid black;
  width: 100%;
  padding: 0;
  margin: 0;
  bottom: 13px;
  z-index: 100;
  background-color: rgba(255, 255, 255, 0.7) !important;
}
.before-enter {
  opacity: 0;
  transform: scale(1);
  transition: all 3s ease-out;
}
.enter {
  opacity: 1;
}
</style>
