import { firestore } from "../../plugins/firebaseConfig";

export default class PlaylistApi {
  static getPlaylist() {
    return firestore
      .collection("playlist")
      .orderBy("time", "desc")
      .get()
      .then((liste) => {
        const playlist = [];
        liste.forEach((doc) => {
          let song = doc.data();
          song.id = doc.id;
          playlist.push(song);
        });
        return playlist;
      });
  }

  static saveSong(payload) {
    return firestore
      .collection("playlist")
      .doc(payload.docName)
      .set(payload.song)
      .then(() => {
        return this.getPlaylist().then((playlist) => playlist);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  static deleteSong(payload) {
    console.log("lösche", payload);

    return firestore.collection("playlist").doc(payload).delete();
  }
}
