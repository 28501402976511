export default class Guest {
  id;
  nr;
  company;
  freitext;
  guestName;
  show;
  time;
  table;

  constructor() {
    this.id = undefined;
    this.nr = undefined;
    this.company = undefined;
    this.freitext = undefined;
    this.guestName = undefined;
    this.show = undefined;
    this.time = undefined;
    this.table = undefined;
  }

  withId(value) {
    this.id = value;
    return this;
  }

  withNr(value) {
    this.nr = value;
    return this;
  }

  withcompany(value) {
    this.company = value;
    return this;
  }

  withfreitext(value) {
    this.freitext = value;
    return this;
  }

  withGuestName(value) {
    this.guestName = value;
    return this;
  }

  withShow(value) {
    this.show = value;
    return this;
  }

  withTime(value) {
    this.time = value;
    return this;
  }

  withTable(value) {
    this.table = value;
    return this;
  }

  static createEmptyGuest() {
    return new Guest();
  }
}
