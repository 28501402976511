import Vue from "vue";
import Vuex from "vuex";
import db from "../plugins/firebaseConfig";
import firebase from "firebase";
import guestlist from "./guestlist";
import globalText from "@/store/globalText";
import router from "@/router";
import chat from "./data/chat.js";
import chatEnglish from "./data/chatEnglish";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    guestList: [],
    groupList: [],
    finished: Boolean,
    loading: false,
    guest: null,
    error: { state: false, message: "" },
    message: null,
    user: null,
    rsvps: [],
    noShows: [],
    answers: [],
    questions: [],
  },
  mutations: {
    ERROR(state, payload) {
      state.error = payload;
    },
    SET_CHAT(state, payload) {
      state.answers = payload.answers;
      state.questions = payload.questions;
    },
    SET_GUEST(state, payload) {
      this.state.guest = payload;
    },
    SET_RSVPS(state, payload) {
      state.rsvps = payload;
    },
    SET_NOSHOWS(state, payload) {
      state.noShows = payload;
    },
    LOAD_GUESTS(state, payload) {
      state.guestList = payload;
      console.info("liste:", state.guestList);
    },
    SET_LOADING(state, payload) {
      state.loading = payload;
    },
    SET_MESSAGE(state, payload) {
      state.message = payload;
    },
    ADD_PERSON(state, payload) {
      state.groupList = payload;
    },
    setUser(state, payload) {
      state.user = {
        id: payload.id,
        firstName: payload.firstName,
        lastName: payload.lastName,
        email: payload.email,
        displayName:
          payload.firstName.charAt(0).toUpperCase() +
          payload.firstName.slice(1),
        group: payload.group,
        status: payload.status,
      };
    },
    setAdmin(state, payload) {
      state.user = payload;
    },
    logOut(state) {
      state.user = null;
      state.message = null;
      console.info("logout successfull.");
    },
    setLoading(state, payload) {
      state.loading = payload;
    },
  },

  actions: {
    initRsvpChat({ commit }) {
      const l = globalText.state.language.name;
      const chatText = {};
      if (l === "Deutsch") {
        Object.assign(chatText, chat);
      } else {
        Object.assign(chatText, chatEnglish);
      }
      let payload = { answers: [], questions: [] };
      commit("SET_CHAT", { answers: [], questions: [] });
      payload.answers = chatText.answers;
      payload.questions = chatText.questions;

      console.info("payload", payload);
      commit("SET_CHAT", payload);
    },
    loadRsvps({ commit }) {
      db.collection("show")
        .get()
        .then((res) => {
          let rsvpPayload = [];
          res.forEach((doc) => {
            let content = doc.data();

            console.log("time", content.time);
            rsvpPayload.push(content);
          });
          console.info("action", rsvpPayload);
          commit("SET_RSVPS", rsvpPayload);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    loadNoShows({ commit }) {
      db.collection("noshow")
        .get()
        .then((res) => {
          let noShowPayload = [];
          res.forEach((doc) => {
            noShowPayload.push(doc.data());
          });
          commit("SET_NOSHOWS", noShowPayload);
        });
    },
    adminLogin({ commit }, payload) {
      console.info("payload", payload);
      firebase
        .auth()
        .signInWithEmailAndPassword("admin@wedding.de", "H!r0n!m085609")
        .then((res) => {
          commit("setLoading", false);
          console.log("admin login", res);
          db.collection("users")
            .where("id", "==", res.user.uid)
            .get()
            .then((a) => {
              let data;
              a.forEach((p) => (data = p.data()));
              console.info("angemeldet als: ", data);
              commit("setAdmin", data);
              router.push("/admin/admindashboard").catch((error) => {
                console.log("fehler: ", error);
              });
            })
            .catch((err) => {
              this.errorMessage = err.message;
              console.info("account nicht geladen", err);
            });
        });
    },
    userSignUp({ dispatch }, payload) {
      const pass = "wedding";
      firebase
        .auth()
        .createUserWithEmailAndPassword(payload.email, pass)
        .then((res) => {
          let newUser = res.user;
          newUser.collection = "users";
          newUser.group = payload.group;
          newUser.firstName = payload.firstName;
          newUser.lastName = payload.lastName;
          dispatch("addAccount", newUser);
          dispatch("addGuest", newUser);
        })
        .catch(function (error) {
          console.error(error);
        });
    },
    addAccount({ commit }, payload) {
      let db = db.collection(newUser.collection);
      const newUser = {
        id: payload.uid,
        collection: payload.collection,
        firstName: payload.firstName,
        lastName: payload.lastName,
        email: payload.email,
        displayName:
          payload.firstName.charAt(0).toUpperCase() +
          payload.firstName.slice(1),
        group: payload.group.toLowerCase(),
      };
      console.info("New Account: ", newUser);
      db.doc()
        .set(newUser)
        .then(() => {
          console.log("person saved: payload", newUser);
          commit("setUser", newUser);
        });
    },
    addGuest({ commit }, payload) {
      const newGuest = {
        firstName: payload.firstName,
        lastName: payload.lastName,
        group: payload.group,
        status: false,
      };
      db.collection("guests")
        .doc()
        .set(newGuest)
        .then((res) => {
          console.info("Gast angelegt", res);
          commit("setLoading", false);
        });
    },
    initGuestList({ commit }) {
      db.collection("guests")
        .get()
        .then((res) => {
          let persons = [];
          res.forEach((p) => {
            let data = p.data();
            data.id = p.id;
            persons.push(data);
          });
          commit("LOAD_GUESTS", persons);
        });
    },
    async userLogin({ commit }, payload) {
      const userName = "user@wedding.de";
      commit("setLoading", true);
      commit("SET_MESSAGE", "Wird eingelogged");
      firebase
        .auth()
        .signInWithEmailAndPassword(userName, payload.password)
        .then((usercredentials) => {
          firebase
            .auth()
            .currentUser.getIdToken()
            .then((token) => {
              localStorage.setItem("token", token);
            });
          /*User Daten aus der Datenbank holen
           */
          db.collection("users")
            .where("id", "==", usercredentials.user.uid)
            .get()
            .then((a) => {
              let data;
              a.forEach((p) => (data = p.data()));
              commit("SET_MESSAGE", "angemeldet als: " + data.displayName);
              commit("setLoading", false);
              commit("setUser", data);
            })
            .then(() => {})
            .catch((err) => {
              commit("SET_MESSAGE", err.message);
            });
        })
        .catch((error) => {
          commit("setLoading", false);
          commit("SET_MESSAGE", error.message);
        });
    },
    async loadGroup({ commit, state }) {
      db.collection("guests")
        .where("group", "==", state.user.group)
        .get()
        .then((res) => {
          let list = [];
          res.forEach((p) => {
            let data = p.data();
            data.id = p.id;
            list.push(data);
          });
          commit("ADD_PERSON", list);
        })
        .catch((error) => {
          console.error("Message", error);
        });
    },
    updateGuests({ state }) {
      state.groupList.forEach((g) =>
        db
          .collection("guests")
          .doc(g.id)
          .update({ status: g.status })
          .then(() => {
            console.info(g.firstName, " updated.");
          })
      );
    },
    deleteGuest({ dispatch }, guest) {
      let name = guest.firstName;
      db.collection("guests")
        .doc(guest.id)
        .delete()
        .then(() => {
          console.info(name, " deleted.");
        });
      dispatch("initGuestList");
    },
    userLogOut({ commit }) {
      firebase
        .auth()
        .signOut()
        .then(() => {
          commit("ERROR", { state: false, message: "" });
          commit("logOut");
        });
    },
    async autoSignIn({ commit }, payload) {
      console.log("autoSignIn", payload.uid);
      db.collection("users")
        .where("id", "==", payload.uid)
        .get()
        .then((a) => {
          let data;
          a.forEach((p) => (data = p.data()));
          console.info("angemeldet als: ", data);
          commit("SET_MESSAGE", "angemeldet als: " + data.displayName);
          commit("setLoading", false);
          commit("setUser", data);
        })
        .catch((err) => {
          commit("SET_MESSAGE", err.message);
          console.info("User nicht geladen", err);
        });
    },
  },
  getters: {
    getAnswers: (state) => {
      return state.answers;
    },
    getQuestions: (state) => {
      return state.questions;
    },
    getNoShows: (state) => {
      return state.noShows;
    },
    getRsvps: (state) => {
      return state.rsvps;
    },
    getGuestList: (state) => {
      return state.guestList;
    },
    getGroupList: (state) => {
      return state.groupList;
    },
    getGuest: (state) => {
      return state.guest;
    },
    getUser: (state) => {
      return state.user;
    },
    getError: (state) => {
      return state.error;
    },
    getLoading: (state) => {
      return state.loading;
    },
    getMessage: (state) => {
      return state.message;
    },
  },
  modules: {
    guestlist,
    globalText,
  },
});
