<template>
  <v-footer padless>
    <v-col class="text-center josefin" cols="12">
      {{ new Date().getFullYear() }} — <strong>Cuci & Petzi</strong>
      <v-spacer> </v-spacer>
      <router-link class="text-sm-caption" to="/impressum"
        >Impressum</router-link
      >
    </v-col>
  </v-footer>
</template>

<script>
export default {
  name: "Footer",
};
</script>

<style scoped></style>
