<template>
  <v-container class="ma-0" fluid style="">
    <v-row justify="center">
      <v-col cols="12" md="6">
        <v-card>
          <v-card-title>Admin Login</v-card-title>
          <form @submit.prevent="adminLogin">
            <v-card-text>
              <v-text-field
                v-model="admin.userName"
                label="Name"
              ></v-text-field>
              <v-text-field
                v-model="admin.password"
                type="password"
                label="Password"
              ></v-text-field>
            </v-card-text>
            <v-card-actions>
              <v-btn type="submit">Login</v-btn>
            </v-card-actions>
          </form>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <router-view />
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "AdminLogin",
  data: () => ({
    admin: {
      userName: "",
      password: "",
    },
  }),
  methods: {
    adminLogin() {
      console.info("Admin login", this.admin);
      //if (this.admin.userName !== "") {
      this.$store.dispatch("adminLogin", this.admin);
      //}
    },
  },
};
</script>

<style scoped></style>
