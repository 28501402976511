import { fireAuth } from "../plugins/firebaseConfig";

export default (to, from, next) => {
  const userId = fireAuth.currentUser.uid;
  if (userId === "hkirvo9Dz7g08ffiCauVtEkiDGG2") {
    next();
  } else {
    console.log("Falscher Benutzer");
    next("/");
  }
};
