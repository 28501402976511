export default {
  answers: [
    {
      id: "a1",
      name: "Ja, ich komme zur Hochzeit",
      key: "show",
      value: "show",
      type: "button",
      parents: ["q1"],
      justify: "end",
    },
    {
      id: "a2",
      name: "Nein, ich kann leider nicht kommen",
      key: "show",
      value: "noshow",
      type: "button",
      parents: ["q1"],
      justify: "end",
    },
    {
      id: "a3",
      key: "guestName",
      value: "",
      description: "Name",
      type: "textfield",
      parents: ["q2"],
      justify: "end",
    },
    {
      id: "a103",
      key: "guestName",
      value: "",
      description: "Name",
      type: "textfield",
      parents: ["q22"],
      justify: "end",
    },
    {
      id: "a105",
      key: "company",
      value: "",
      name: "Mich hätte begleitet: <br>",
      description: "Name der Begleitung",
      type: "combobox",
      parents: ["q103"],
      justify: "end",
    },
    {
      id: "a4",
      key: "freitext",
      value: "",
      description: "Anmerkungen",
      type: "textfield",
      parents: ["q4"],
      justify: "end",
    },
    {
      id: "a5",
      key: "company",
      value: "",
      name: "Ich bringe mit: <br>",
      description: "Name der Begleitung",
      type: "combobox",
      parents: ["q3"],
      justify: "end",
    },
  ],
  questions: [
    {
      id: "q1",
      name: "Willkommen! Bitte sag uns, ob du zur Hochzeit kommst!",
      type: "q",
      parents: [],
      justify: "start",
    },
    {
      id: "q2",
      name: "Sehr schön! <br> Wie ist dein Name? <br>Deine Begleitung(en) kannst du uns im nächsten Schritt mitteilen.",
      type: "q",
      parents: ["a1"],
      justify: "start",
    },
    {
      id: "q22",
      name: "Das ist sehr schade! :-( <br> Wie ist dein Name?",
      type: "q",
      parents: ["a2"],
      justify: "start",
    },
    {
      id: "q3",
      name: "Wer begleitet dich?",
      type: "q",
      parents: ["a3"],
      justify: "start",
    },
    {
      id: "q103",
      name: "Wer hätte dich begleitet?",
      type: "q",
      parents: ["a103"],
      justify: "start",
    },
    {
      id: "q4",
      name: "Möchtest du uns noch etwas mitteilen? <br> Wird ein Hochstuhl benötigt?<br>Gibt es Allergien oder Unverträglichkeiten?<br>Oder hintelasse hier einfach deine Grüße.",
      type: "q",
      parents: ["a5", "a105"],
      justify: "start",
    },
  ],
};
