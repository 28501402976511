<template>
  <v-dialog :width="$vuetify.breakpoint.smAndDown ? '100%' : '50%'">
    <template v-slot:activator="{ on }">
      <v-btn v-on="on">Playlist</v-btn>
    </template>

    <v-card>
      <playlist-list
        :playlist="playlist"
        :admin="true"
        @update="loadPlaylist"
      />
      <v-card-actions>
        <download-excel
          class="v-btn elevation-2"
          style="cursor: pointer"
          :fields="json_fields"
          :data="json_data"
          worksheet="playlist"
          name="playlist.xls"
          >Download als Excel</download-excel
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import PlaylistList from "./playlistList";
import PlaylistApi from "../api/PlaylistApi";
export default {
  name: "playlist-popup",
  components: { PlaylistList },
  data: () => ({
    playlist: [],
    json_fields: { Artist: "artist", Title: "song", "Eingereicht von": "name" },
    json_data: [],
    json_meta: [
      [
        {
          key: "charset",
          value: "utf-8",
        },
      ],
    ],
  }),
  methods: {
    loadPlaylist() {
      PlaylistApi.getPlaylist().then((res) => {
        this.playlist = res;
        res.forEach((item) => {
          this.json_data.push(item);
        });
      });
    },
  },
  computed: {},
  beforeMount() {
    this.loadPlaylist();
  },
};
</script>

<style scoped></style>
