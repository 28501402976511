import Vue from "vue";
import VueRouter from "vue-router";
import AuthGuard from "./auth-guard";
import AdminAuth from "./adminauth";
import Admin from "@/views/Admin";
import AdminLogin from "@/views/AdminLogin";
import AdminDashboard from "@/views/AdminDashboard";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Welcome",
    props: true,
    component: () => import("@/views/Home"),
  },
  {
    path: "/wedding",
    name: "Wedding",
    props: true,
    component: () => import("@/views/Wedding"),
    beforeEnter: AuthGuard,
  },
  {
    path: "/rsvpchat",
    name: "Chat",
    component: () => import("@/views/RsvpChat"),
    beforeEnter: AuthGuard,
  },
  {
    path: "/thankYou",
    name: "ThankYou",
    props: true,
    component: () => import("@/components/ThankYou"),
  },
  {
    path: "/admin",
    name: "Admin",
    component: Admin,
    children: [
      {
        path: "",
        component: AdminLogin,
      },
      {
        path: "admindashboard",
        component: AdminDashboard,
        beforeEnter: AdminAuth,
      },
    ],
  },
  {
    path: "/addguests",
    name: "editguests",
    component: () => import("../views/AddGuests"),
    beforeEnter: AuthGuard,
  },
  {
    path: "/signup",
    name: "Signup",
    component: () => import("../views/SignUp"),
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("../views/Login"),
  },
  {
    path: "/rsvp",
    name: "Rsvp",
    component: () => import("../views/Rsvp"),
    beforeEnter: AuthGuard,
  },
  {
    path: "/rsvpform",
    name: "RsvpForm",
    component: () => import("../views/RsvpCheck"),
    beforeEnter: AuthGuard,
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
  {
    path: "/impressum",
    name: "impressum",
    component: () => import("@/components/entries/Impressum"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
