export default {
  thankyouforcoming: {
    title: "Thank you for this wonderful day!",
    text1:
      "It was truly a wonderful celebration and <b>YOU made this day so memorable for us!</b><br>\n" +
      "We would like to thank you! Tusen takk!<p>\n",
    text2:
      "You can access the gallery of our photographer Marie Hornbergs via the following link:<br>",
    text3:
      "Also some pictures were taken with the photo booth. You can find them in the following photo gallery:",
    text4:
      "The video of the wedding will also be available soon on this website.",
    gallerieLink:
      "https://mariehornbergsphotography.pic-time.com/O6A29OTpzI5Eh",
    disclaimer:
      "***<br>Of course, you can download and use the pictures. Please remember to mention the copyrights of our photographer Marie Hornbergs Photography when publishing. " +
      "Please do not use any filters and do not edit them.<br>" +
      "She thanks you for your understanding.<br>" +
      "***",
    videoText:
      "During the celebration a very nice video was created, which you can watch via the following link.",
    videoButtonText: "Weddingvideo",
    videoButtonTextAlt: "Alternativlink"
  },
  playlist: {
    description:
      "For the right party sound, there will be a DJ so no one will remain seated.<br>" +
      "To have something for everyone in the playlist, you are welcome to add your favorite Songs here.",
  },
  login: {
    title: "Login",
    password: "Password",
    info: "That password was communicated to you. <br> If not, please send us a message and we will provide you with the password.",
  },
  rsvpButton: {
    text: "Respond / RSVP",
  },
  info: {
    text: "Im Moment befindet sich diese Seite noch im Aufbau, deshalb stehen noch nicht alle Informationen zur Verfügung.<p> Bis dahin könnt ihr euch aber schon mal mit dem RSVP-Tool anmelden.",
  },
  dresscode: {
    text: "We're happy to welcome you in elegant summer clothing.",
  },
  programmItems: {
    title: "Program",
    items: [
      {
        id: "1",
        time: "14:00",
        title: "Arrival - reception of the guests in the garden",
        subtitle: "",
      },
      {
        id: "2",
        time: "14:30",
        title: "Wedding ceremony, on the meadow behind the venue",
        subtitle: "",
      },
      {
        id: "3",
        time: "15:15",
        title: "Reception, canapés and pictures",
        subtitle: "",
      },
      {
        id: "4",
        time: "16:30",
        title: "Group photo with all guests",
        subtitle: "",
      },
      { id: "5", time: "17:00", title: "Wedding cake", subtitle: "" },
      {
        id: "6",
        time: "18:30",
        title: "Opening of the beer barrel",
        subtitle: "",
      },
      { id: "7", time: "18:45", title: "Wedding dinner", subtitle: "" },
      { id: "8", time: "20:00", title: "Raffle of quiz prizes", subtitle: "" },
      { id: "9", time: "20:30", title: "Dessert", subtitle: "" },
      {
        id: "10",
        time: "21:15",
        title: "Wedding dance of bride and groom",
        subtitle: "",
      },
      { id: "11", time: "21:20", title: "Party!", subtitle: "" },
      { id: "12", time: "01:00", title: "End", subtitle: "" },
    ],
  },
  questions: {
    title: "Q & A",
    items: [
      {
        id: 1,
        question: "Dresscode?",
        answer:
          "We look forward to welcome you in summer chic.<p>Of course, wearing Dirndl or Lederhosen is also welcome and fits perfectly with the ambience.",
      },
      {
        id: 2,
        question: "What is a good wedding gift?",
        answer:
          "While it is a wedding tradition to bring a gift for the couple, our greatest gift is to finally be able to celebrate with you. <br>" +
          "Nevertheless, if you would like to give us something, we will be happy to receive an envelope.",
      },
      {
        id: 3,
        question: "How can I reach you personally?",
        answer:
          "You can contact us directly:<p>" +
          "<p>E-Mail: cp.bussler@gmail.com<br>" +
          "Cuci: 00491795368099<br>Petzi: 00491791000915</p>",
      },
    ],
  },
  staff: {
    title: {
      title1: "Our Witnesses",
    },
    content: [
      {
        title: "",
        items: [
          {
            name: "Thao Pham",
            text: "Maid of honor",
            avatar: require("@/assets/trauzeugen/Thao_avatar.png"),
            contact: {
              tel: "004915758511642",
              email: "pham_thaoi@hotmail.com",
            },
          },
          {
            name: "Julien Lang (1988 - 2021)",
            text: "Best man of the groom",
            avatar: require("@/assets/trauzeugen/Julien_avatar02.png"),
          },
        ],
      },
      {
        title: "Master of Ceremonies",
        items: [
          {
            name: "Mymy Tran",
            text: "Head of Organisation",
            avatar: require("@/assets/trauzeugen/avatar_mymy.png"),
          },
        ],
      },
      {
        title: "The Wedding speaker",
        items: [
          {
            name: "Korbinian Weise",
            text: "Wedding speaker",
            avatar: require("@/assets/trauzeugen/korbi_avatar.png"),
          },
        ],
      },
    ],
  },
  timer: {
    day: "Days",
    hours: "Hours",
    minutes: "Minutes",
    seconds: "Seconds",
    expired: "Event has been expired.",
    running: "until the end of wedding",
    upcoming: "until start of wedding",
    status: {
      expired: "Expired",
      running: "Running",
      upcoming: "Future",
    },
  },
  hotel: {
    title: "Hotels",
    hotels: [
      {
        id: "brauereigasthof",
        name: "Brauereigasthof Hotel Aying",
        text: "The hotel is located just a few minutes walk from the venue and belongs to the brewery.",
        to: "https://www.brauereigasthof-aying.de/",
        img: require("@/assets/brauereigasthof.jpg"),
      },
      {
        id: "Kastanienhof",
        name: "Kastanienhof",
        text: "<strong>Please mention our name 'Bussler' when booking.</strong><p>The hotel is also located a few minutes walk from the venue.",
        to: "https://kastanienhof-aying.de/",
        img: require("@/assets/brauereigasthof.jpg"),
      },
    ],
  },
  home: {
    title: "Herzlich willkommen!<br>Welcome!<br>Velkommen!",
    text: "Please login to get further information.",
  },
  wannundwo: {
    title: "Dear family, dear friends!",
    text1:
      "Finally it is possible to celebrate our wedding." +
      "    We would like to spend the most beautiful day in our lives with those people who are the most important for us.<br> " +
      "We would like to invite you to our wedding on",
    textwann: "Saturday, 18th June 2022 at 2 pm.",
    text2:
      "The ceremony begins at 2.30 pm.<br>Afterwards we will celebrate this special occasion with you. <br>",
    text3:
      "We are looking forward to welcoming you as our guests and would kindly ask you to reply by <br><strong> May 1, 2022.</strong>",
  },
  location: {
    text:
      "<p><a href='https://www.brauereigasthof-aying.de/ueber-uns/das-ensemble/schalander' target='_blank'>Schalander</a></p><p>Brauerei Aying Franz Inselkammer</p><p>Münchener Str. 21</p><p>85653 Aying</p>" +
      "<p>There are enough parking spaces in front of the building.</p>",
    transportTitle: "Public Transport<br> (S-Bahn)",
    transport:
      "<p>There is a S-Bahn station named 'Aying'of the line S7 and the venue can ve reached within 9 minutes walking distance from the station</p><p>The S7 from Aying into town leaves at 00:51 and at 01:51 via Neuperlach-Süd, Giesing, Ostbahnhof to Munich Hauptbahnhof within 42 min.</p>",
  },
  thankyou: {
    thanks: "Thank you",
    show: "You informed us that you come ",
    show1: "with ",
    show2: "and",
    show3: "alone.",
    show4: "",
    noshow: "Too bad, that you cannot come.",
    noshow2: "And a pity, that ",
    noshow3: " also does not come.",
    lieber: "Dear",
    text1: ", great!<br> We look forward to seeing you!",
    message: "Your message to us: ",
    back: "Back to homepage",
  },
  rsvp: {
    title: "Hello, please let us know whether you can come to our wedding.",

    showText: "Yes, I’m in!",
    noShowText: "Sorry, I cannot join!",
    text1: "I ",
    name: "First name, family name",
    text2: "will come to your wedding and my company is ",
    company: "company",
    companyName: "First name and family name",
    addCompany: "add company",
    optional: "Optional",
    optionText: "Vegetarian",
    text3: "",
    text3Hint:
      "Would you like to leave a message or greetings? Do you need a high chair for a child? Are there any allergies or intolerances?",
    buttonText: "Senden",
    dialogText: "Alles richtig?",
  },
};
