export default {
  thankyouforcoming: {
    title: "Vielen Dank für diesen tollen Tag!",
    text1:
      "Es war ein wirklich wunderbares Fest und <b>ihr habt diesen Tag für uns so unvergesslich gemacht!</b><br>\n" +
      "          Wir möchten uns hiermit nochmal ganz herzlich bei euch bedanken!<p>\n" +
      "          Vielen vielen Dank!</p>",
    text2:
      "Und da an diesem Tag viele Fotos gemacht wurden, könnt ihr über folgenden Link auf die Galerie unserer Fotografin Marie Hornbergs zugreifen:<br>",
    text3:
      "Außerdem wurden einige Bilder mit der Photo Booth gemacht. Diese findet ihr in der folgenden Fotogalerie:",
    text4: "Das Hochzeitsvideo folgt auch hier in Kürze.",
    gallerieLink:
      "https://mariehornbergsphotography.pic-time.com/O6A29OTpzI5Eh",
    disclaimer:
      "***<br>Natürlich dürft ihr die Bilder herunterladen und verwenden. Bitte denkt an die Nennung der Urheberrechte unserer Fotografin Marie Hornbergs Photography, wenn ihr" +
      "die Fotos im Internet veröffentlicht. Bitte legt keine Filter über die Bilder und bearbeitet sie nicht nach. Sie dankt für euer Verständnis.<br>***",
    videoText:
      "Während der Feier ist ein sehr schönes Video entstanden, welches\n" +
      "              ihr über folgenden Link ansehen könnt.",
    videoButtonText: "Hochzeitsvideo",
    videoButtonTextAlt: "Alternativlink"
  },
  playlist: {
    description:
      "Für die musikalische Untermalung der Party am Abend wird es einen DJ geben, " +
      "so dass niemand sitzen bleibt.<p>" +
      "Damit in der Playlist auch für jeden was dabei ist, dürft ihr hier gerne Musikwünsche hinzufügen.",
  },
  login: {
    title: "Login",
    password: "Passwort",
    info: "Passwort wurde euch mitgeteilt. <br> Falls nicht, schickt uns einfach schnell eine Nachricht und wir teilen es euch umgehend mit.",
  },
  rsvpButton: {
    text: "Rückmeldung / RSVP",
  },
  info: {
    text: "Im Moment befindet sich diese Seite noch im Aufbau, deshalb stehen noch nicht alle Informationen zur Verfügung.<p> Bis dahin könnt ihr euch aber schon mal mit dem RSVP-Tool anmelden.",
  },
  dresscode: {
    text: "Wir freuen uns, euch in sommerlich eleganter Kleidung begrüßen zu dürfen.",
  },
  staff: {
    title: {
      title1: "Unsere Trauzeugin und Trauzeuge",
      title2: "Die Zeremonienmeisterin",
    },
    content: [
      {
        title: "",
        items: [
          {
            name: "Thao Pham",
            text: "Trauzeugin der Braut",
            avatar: require("@/assets/trauzeugen/Thao_avatar.png"),
            contact: {
              tel: "004915758511642",
              email: "pham_thaoi@hotmail.com",
            },
          },
          {
            name: "Julien Lang (1988 - 2021)",
            text: "Trauzeuge des Bräutigams",
            avatar: require("@/assets/trauzeugen/Julien_avatar02.png"),
          },
        ],
      },
      {
        title: "Die Zeremonienmeisterin",
        items: [
          {
            name: "Mymy Tran",
            text: "Head of Organisation",
            avatar: require("@/assets/trauzeugen/avatar_mymy.png"),
          },
        ],
      },
      {
        title: "Der Trauredner",
        items: [
          {
            name: "Korbinian Weise",
            text: "Trauredner",
            avatar: require("@/assets/trauzeugen/korbi_avatar.png"),
          },
        ],
      },
    ],
  },
  timer: {
    day: "Tage",
    hours: "Stunden",
    minutes: "Minuten",
    seconds: "Sekunden",
    expired: "18.06.2022 <br>\nEs war uns eine Ehre mit euch zu feiern!",
    running: "JETZT WIRD GEFEIERT!!!!",
    upcoming: "bis zur Hochzeitsfeier",
    status: {
      expired: "Expired",
      running: "Running",
      upcoming: "Future",
    },
  },
  geschenk: {
    title: "Hochzeitsgeschenk",
    text:
      "Es ist zwar ein Brauch zur Hochzeit, dem Ehepaar ein Geschenk zu bringen, doch unser größtes Geschenk ist es, <br>" +
      "endlich mit euch feiern zu dürfen. Dennoch, wenn ihr uns etwas schenken möchtet, wäre es das einfachste etwas Geld zu schenken, " +
      "um uns mit den Kosten zu helfen die wir seit zwei Jahren haben.",
  },
  programmItems: {
    title: "Programm",
    items: [
      {
        id: "1",
        time: "14:00",
        title: "Beginn - Empfang der Gäste im Garten",
        subtitle: "",
      },
      {
        id: "2",
        time: "14:30",
        title: "Freie Trauung, im Garten hinter dem Schalander",
        subtitle: "",
      },
      {
        id: "3",
        time: "15:15",
        title: "Sektempfang, Häppchen und Fotos",
        subtitle: "",
      },
      { id: "4", time: "16:30", title: "Großes Gruppenfoto", subtitle: "" },
      { id: "5", time: "17:00", title: "Hochzeitstorte", subtitle: "" },
      {
        id: "6",
        time: "18:30",
        title: "Fassanstich im Schalander",
        subtitle: "",
      },
      { id: "7", time: "18:45", title: "Hochzeitsdinner", subtitle: "" },
      {
        id: "8",
        time: "20:00",
        title: "Verlosung der Quizpreise",
        subtitle: "",
      },
      { id: "9", time: "20:30", title: "Nachtisch-Buffet", subtitle: "" },
      {
        id: "10",
        time: "21:15",
        title: "Hochzeitstanz des Brautpaars",
        subtitle: "",
      },
      {
        id: "11",
        time: "21:20",
        title: "Party bis zum Umfallen!",
        subtitle: "",
      },
      { id: "12", time: "01:00", title: "Ende", subtitle: "" },
    ],
  },
  questions: {
    title: "Fragen und Antworten",
    items: [
      {
        id: 1,
        question: "Wie ist der Dresscode?",
        answer:
          "Wir freuen uns, euch in sommerlich eleganter Kleidung begrüßen zu dürfen.<br>Natürlich ist auch das Tragen von Dirndl oder Lederhose gerne gesehen und passt hervorragend zur Kulisse.",
      },
      {
        id: 2,
        question: "Was sollen wir schenken?",
        answer:
          "Es ist zwar ein Brauch zur Hochzeit, dem Ehepaar ein Geschenk zu bringen, doch unser größtes Geschenk ist es," +
          "endlich mit euch feiern zu dürfen. <br>Dennoch, wenn ihr uns etwas schenken möchtet, freuen wir uns über ein Kuvert.",
      },
      {
        id: 3,
        question: "Wie kann ich euch persönlich erreichen?",
        answer:
          "Ihr könnt uns gerne direkt erreichen:<p>" +
          "<p>E-Mail: cp.bussler@gmail.com<br>" +
          "Cuci: 01795368099<br>Petzi: 01791000915</p>",
      },
    ],
  },
  hotel: {
    title: "Hotels",
    hotels: [
      {
        id: "brauereigasthof",
        name: "Brauereigasthof Hotel Aying",
        text: "Das Hotel befindet sich wenige Gehminuten vom Schalander entfernt und gehört zur Brauerei.",
        to: "https://www.brauereigasthof-aying.de/",
        img: require("@/assets/brauereigasthof.jpg"),
      },
      {
        id: "Kastanienhof",
        name: "Kastanienhof",
        text: "<strong>Bitte gebt bei der Buchung unseren Namen mit an.</strong><p>Das Hotel befindet sich ebenfalls wenige Gehminuten vom Schalander entfernt in Aying.",
        to: "https://kastanienhof-aying.de/",
        img: require("@/assets/brauereigasthof.jpg"),
      },
    ],
  },
  home: {
    title: "Herzlich willkommen!<br>Welcome!<br>Velkommen!",
    text: "Wenn du dich anmeldest, bekommst du weitere Informationen.<p> Please login to get further information.</p>",
  },
  wannundwo: {
    title: "Liebe Familie, liebe Freunde!",
    text1:
      "Endlich können wir nach zweieinhalb Jahren unsere Hochzeit feiern. " +
      "Wir wollen den schönsten Tag in unserem Leben mit den Menschen verbringen, die uns am wichtigsten sind.<br>" +
      "Deswegen laden wir euch von Herzen zu unserer Hochzeitsfeier ein.",
    textwann: "Am Samstag, den 18. Juni 2022 um 14:00 Uhr",
    text2:
      "Die freie Trauung beginnt um 14:30 Uhr.<br>Beim anschließenden Fest möchten wir dieses besondere Ereignis mit euch gemeinsam feiern.<br>",
    text3:
      "Wir freuen uns sehr auf euer Kommen<br>und bitten um Zusage bis 1. Mai 2022.",
  },
  location: {
    text:
      "<p><a href='https://www.brauereigasthof-aying.de/ueber-uns/das-ensemble/schalander' target='_blank'>Schalander</a></p><p>Brauerei Aying Franz Inselkammer</p><p>Münchener Str. 21</p><p>85653 Aying</p>" +
      "<p>Es stehen ausreichend Parkplatze vor dem Gebäude zur Verfügung.</p>",
    transportTitle: "S-Bahn",
    transport:
      "<p>Im Ort gibt es die S-Bahn Station 'Aying' der Linie S7 und den Schalander erreicht man innerhalb von 9 Gehminuten von der S-Bahn Station aus.</p>Die S7 ab Aying stadteinwärts fährt um <b>00:51 und um 01:51</b> über Neuperlach-Süd, Giesing, Ostbahnhof bis zum Münchner Hauptbahnhof innerhalb von 42 Min.",
  },
  thankyou: {
    thanks: "Vielen Dank!",
    show: "Du hast angeben, dass du ",
    show1: "mit: ",
    show2: "und",
    show3: "alleine ",
    show4: "kommst.",
    noshow: "Schade das du nicht kommen kannst",
    noshow2: "Und schade das ",
    noshow3: " auch nicht kommt.",
    lieber: "Liebe/r",
    text1: ", schön, dass du kommst. Wir freuen uns auf dich!",
    message: "Deine Nachricht für uns: ",
    back: "Zurück zur Homepage",
  },
  rsvpchat: {
    title: "Hallo, bitte sag uns ob du zur Hochzeitsfeier kommen kannst.",
    showText: "Ich werde zur Hochzeitsfeier kommen.",
    noShowText: "Ich kann leider nicht kommen.",
    text1: "Ich, ",
    name: "Vorname, Nachname",
    text2:
      "werde zu eurer Hochzeit kommen und bringe folgende Begleitung mit: ",
    company: "Begleitung: ",
    companyName: "Vor- und Nachname der Begleitung",
    addCompany: "Begleitung hinzufügen",
    optional: "Optional",
    optionText: "Vegetarisch",
    text3: "Sonstiges",
    text3Hint:
      "Bitte informiere uns über eventuelle Allergien oder ob du einen Hochstuhl brauchst.",
    buttonText: "Senden",
    dialogText: "Alles richtig?",
  },
};
