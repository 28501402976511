import db from "../../plugins/firebaseConfig";
import moment from "moment";
import Guest from "../types/Guest";

export default class Database {
  static getGuests(prop) {
    return db
      .collection(prop)
      .orderBy("time", "desc")
      .get()
      .then((res) => {
        let rsvpPayload = [];
        res.forEach((doc, i) => {
          let guestData = doc.data();
          const date = moment
            .unix(guestData.time.seconds)
            .format("DD.MM.YYYY HH:MM");
          let guest = Guest.createEmptyGuest()
            .withNr(i)
            .withGuestName(guestData.guestName)
            .withTime(date)
            .withId(doc.id)
            .withShow(prop)
            .withcompany(guestData.company)
            .withfreitext(guestData.freitext)
            .withTable("Tisch 1");
          rsvpPayload.push(guest);
        });
        return rsvpPayload;
      })
      .catch((error) => {
        console.log(error);
      });
  }

  static saveRSVP(payload) {
    db.collection(payload.show)
      .doc(payload.guestName)
      .set(Object.assign({}, payload))
      .then((res) => {
        console.info("Gast angelegt", res);
      });
  }

  static deleteGuest(item) {
    console.log("delete Guest");
    return db
      .collection(item.show)
      .doc(item.id)
      .delete()
      .then(() => item + " gelöscht")
      .catch((error) => {
        console.log("fehler beim löschen", error);
      });
  }
}
