import textEnglish from "./data/textEnglish";
import textGerman from "./data/textGerman";

const locales = [
  { name: "Deutsch", textImport: textGerman },
  { name: "Englisch", textImport: textEnglish },
];

function getPossibleLocales(number) {
  if (number) {
    return locales[number];
  } else {
    return locales;
  }
}

const state = {
  locales: getPossibleLocales(),
  language: "Deutsch",
  texts: null,
};
const mutations = {
  SET_TEXT(state, textObj) {
    state.texts = textObj;
  },
  SET_LANGUAGE(state, locale) {
    console.log("Sprache: ", locale.name);
    state.language = locale;
  },
};
const actions = {
  setTexts({ commit }, locale) {
    console.log("texte setzen", state.locales[locale].textImport);
    commit("SET_TEXT", state.locales[locale].textImport);
    commit("SET_LANGUAGE", state.locales[locale]);
  },
};
const getters = {
  getText: (state) => {
    return state.texts;
  },
  getLocales: (state) => {
    return state.locales;
  },
  getLanguage: (state) => {
    return state.language;
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};
