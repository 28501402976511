<template>
  <v-container fluid>
    Loading: {{ loading }}
    <v-row>
      <v-col cols="12">
        <v-toolbar>
          <v-toolbar-items>
            <v-btn @click="loadRsvp">Laden</v-btn>
            <playlist-popup />
          </v-toolbar-items>
        </v-toolbar>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="12" md="12" lg="6">
        <v-card>
          <v-card-title
            >Zusagen
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            ></v-text-field
          ></v-card-title>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h5"
                >Are you sure you want to delete this item?</v-card-title
              >
              <v-card-text>{{ this.editedItem }}</v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete"
                  >Cancel</v-btn
                >
                <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                  >OK</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-data-table
            :loading="loading"
            :items-per-page="itemsPerPage"
            :headers="headers"
            :items="shows"
            :search="search"
            show-expand
            :footer-props="footerProps"
          >
            <template v-slot:item.guestName="{ item }">
              <strong class="wedding-green">{{ item.guestName }}</strong>
            </template>
            <template v-slot:expanded-item="{ headers, item }">
              <td class="py-2" :colspan="headers.length">
                <p><strong>Nachricht:</strong><br />{{ item.freitext }}</p>
                <strong>Begleitung: </strong>
                <ul>
                  <li v-for="c in item.company" :key="c">
                    {{ c }}
                  </li>
                </ul>
              </td>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-icon small @click="deleteItem(item)">mdi-delete</v-icon>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
      <v-col cols="12" md="12" lg="6">
        <v-card>
          <v-card-title>Absagen</v-card-title>
          <v-data-table
            dense
            :headers="headers"
            :items-per-page="itemsPerPage"
            :items="noShows"
          >
            <template v-slot:item.actions="{ item }">
              <v-icon small @click="deleteItem(item)">mdi-delete</v-icon>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Database from "../components/api/Database";
import PlaylistPopup from "../components/features/playlist-popup";

export default {
  name: "AdminDashboard",
  components: { PlaylistPopup },
  data: () => ({
    loading: false,
    itemsPerPage: 50,
    footerProps: { itemsPerPageOptions: [15, 25, 50, 100] },
    expanded: [],
    user: {},
    dialogDelete: false,
    search: "",
    headers: [
      //{ text: "Begleitung", value: "company" },
      //{ text: "Bemerkung", value: "freitext" },
      {
        text: "Name",
        value: "guestName",
      },
      { text: "Zeit", value: "time" },
      { text: "bearbeiten", value: "actions" },
      { text: "", value: "data-table-expand" },
    ],
    shows: [],
    noShows: [],
    editedIndex: -1,
    editedItem: null,
  }),
  computed: {
    rsvps() {
      return this.Sort(this.$store.getters.getRsvps);
    },
    noShow() {
      return this.Sort(this.$store.getters.getNoShows);
    },
  },
  watch: {
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },
  methods: {
    Sort(array) {
      let sortedArray = array;
      array
        .sort((a, b) => {
          let dateA = new Date(a.time);
          let dateB = new Date(b.time);
          return dateA - dateB;
        })
        .reverse();
      return sortedArray;
    },
    getUser() {
      this.user = this.$store.getters.getUser;
    },
    loadRsvp() {
      this.loading = true;
      this.shows = [];
      this.noShows = [];
      Database.getGuests("show")
        .then((guestlist) => {
          this.shows = guestlist;
        })
        .then(() => {
          Database.getGuests("noshow").then((guestlist) => {
            this.noShows = guestlist;
          });
        })
        .finally((this.loading = false));
    },
    loadNoShows() {
      this.$store.dispatch("loadNoShows");
    },
    deleteItem(item) {
      this.editedItem = item;
      this.dialogDelete = true;
    },
    deleteItemConfirm() {
      //firebase delete
      Database.deleteGuest(this.editedItem).then(() => {
        this.loadRsvp();
      });
      this.closeDelete();
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, null);
      });
    },
  },
  beforeMount() {
    this.loadRsvp();
    //this.loadNoShows();
  },
};
</script>

<style scoped></style>
